import React, { useReducer, createContext } from "react";
import globalContextReducer from "../reducers/GlobalContextReducer";

export const GlobalStateContext = createContext(null);
export const GlobalDispatchContext = createContext(null);

const initialState: any = {
  mobileSearchFilterOpen: false,
};

function GlobalContextProvider({ children }) {
  const [state, dispatch] = useReducer(globalContextReducer, initialState);

  return (
    <GlobalStateContext.Provider value={state}>
      <GlobalDispatchContext.Provider value={dispatch}>
        {children}
      </GlobalDispatchContext.Provider>
    </GlobalStateContext.Provider>
  );
}

export default GlobalContextProvider;
