import React from "react";
import GlobalContextProvider from "./src/context/GlobalContextProvider";
// import FacetContextProvider from "./src/context/FacetContextProvider";
import SearchContextProvider from "./src/context/SearchContextProvider";
import SearchResultsContextProvider from "./src/context/SearchResultsContextProvider";
import AuthenticationContextProvider from "./src/context/AuthenticationContext";
import SubmissionContext from "./src/context/SubmissionContext";
import DictionaryDataContext from "./src/context/DictionaryDataContext";
import LoadDictionaryData from "./src/hoc/LoadDictionaryData";
import "./src/styles/global.css";

export const wrapRootElement = ({ element }) => {
  return (
    <SubmissionContext>
      <DictionaryDataContext>

<LoadDictionaryData>

        <AuthenticationContextProvider>
          <GlobalContextProvider>
            {/* <FacetContextProvider> */}
            <SearchContextProvider>
              <SearchResultsContextProvider>
                {element}
              </SearchResultsContextProvider>
            </SearchContextProvider>
            {/* </FacetContextProvider> */}
          </GlobalContextProvider>
        </AuthenticationContextProvider>

</LoadDictionaryData>

      </DictionaryDataContext>
    </SubmissionContext>
  );
};
