import React, { useReducer, createContext } from "react";
import submissionReducer from "../reducers/SubmissionReducer";
import { initialState } from "../types/initialState";

export const SubmissionState = createContext(null);
export const SubmissionDispatch = createContext(null);

function SubmissionContext({ children }) {
  const [state, dispatch] = useReducer(submissionReducer, initialState);

  return (
    <SubmissionState.Provider value={state}>
      <SubmissionDispatch.Provider value={dispatch}>
        {children}
      </SubmissionDispatch.Provider>
    </SubmissionState.Provider>
  );
}

export default SubmissionContext;
