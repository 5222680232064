const searchContextReducer = (state: any, action: any) => {
  switch (action.type) {
    case "searchChangeHandler": {
      const newState = { ...state };
      newState.searchData.keyword = action.value;
      return newState;
    }

    case "searchResultsHandler": {
      const newState = { ...state };
      newState.searchResults = action.searchResults;
      newState.searchData = action.searchData;
      return newState;
    }

    case "searchDataChangeHandler": {
      const newState = { ...state, searchData: action.searchData };
      return newState;
    }

    case "resultsPerPageChangeHandler": {
      const newState = { ...state };
      newState.searchData.size = action.resultsPerPage;
      return newState;
    }

    case "sortingOptionChangeHandler": {
      const newState = { ...state };
      newState.searchData.sort = action.sortingOption;
      return newState;
    }

    case "searchFacetsHandler": {
      const newState = { ...state };
      newState[action.fieldName] = action.value;
      return newState;
    }

    case "facetChangeHandler": {
      const newState = { ...state };
      newState.searchData.filters[action.fieldName] = action.value;
      return newState;
    }

    case "facetHandler": {
      const newState = { ...state, ...action.state };
      return newState;
    }

    case "searchWithMaterials": {
      const newState = { ...state };
      newState.searchData.filters.containsFiles = [
        { value: "true", label: "Yes" },
      ];
      return newState;
    }

    case "searchAllMaterials": {
      const newState = { ...state };
      newState.searchData.filters.containsFiles = "";
      delete newState.searchData.filters.containsFiles;
      return newState;
    }

    // case "facetAndOrHandler": {
    //   const newState = { ...state };
    //   newState.andOr[action.fieldName] = action.value;
    //   return newState;
    // }

    default:
      console.log("action.type", action.type);
      throw new Error("Bad Action Type");
  }
};

export default searchContextReducer;
