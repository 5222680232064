const AuthenticationReducer = (state: any, action: any) => {
  switch (action.type) {
    case "ADD_TOKEN": {
      let newState = { ...state };
      newState["isAuthenticated"] = true;
      newState["token"] = action.token;
      newState["username"] = action.username;

      // Store details also in a session
      // Perhaps we should change this to `secureStorage`
      // https://github.com/Parking-Master/SecurityJS.128#windowsecurestorage-api
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("idToken", action.token);
      localStorage.setItem("username", action.username);

      return newState;
    }

    case "REMOVE_TOKEN": {
      let newState = { ...state };
      newState["isAuthenticated"] = false;
      newState["token"] = undefined;
      newState["username"] = undefined;

      localStorage.removeItem("isAuthenticated");
      localStorage.removeItem("idToken");
      localStorage.removeItem("username");

      return newState;
    }

    default: {
      console.log(`Invalid action type: ${action.type}`);
      return state;
    }
  }
};

export default AuthenticationReducer;
