import React, { useReducer, createContext } from "react";
import searchResultsContextReducer from "../reducers/SearchResultsContextReducer";

export const SearchResultsStateContext = createContext(null);
export const SearchResultsDispatchContext = createContext(null);

const initialState: any = {};

const SearchResultsContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(
    searchResultsContextReducer,
    initialState
  );

  return (
    <SearchResultsStateContext.Provider value={state}>
      <SearchResultsDispatchContext.Provider value={dispatch}>
        {children}
      </SearchResultsDispatchContext.Provider>
    </SearchResultsStateContext.Provider>
  );
};

export default SearchResultsContextProvider;
