import React, { useReducer, createContext } from "react";
import ddReducer from "../reducers/DictionaryDataReducer";

// DictionaryData

export const DictionaryDataState = createContext(null);
export const DictionaryDataDispatch = createContext(null);

const initialState = {};

function DictionaryDataContext({ children }) {
  const [state, dispatch] = useReducer(ddReducer, initialState);

  return (
    <DictionaryDataState.Provider value={state}>
      <DictionaryDataDispatch.Provider value={dispatch}>
        {children}
      </DictionaryDataDispatch.Provider>
    </DictionaryDataState.Provider>
  );
}

export default DictionaryDataContext;