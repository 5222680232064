/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable array-callback-return */
/* eslint-disable import/prefer-default-export */
/**
 * Make a API call to `endpoint` and create a usable object for our dropdown menu. The dropdown menu requires a {label, value}
 * @param endpoint string
 * @returns {label: <string>, value: <string>}
 */

import { isEmpty } from "lodash";

export const createDropdownList = (dd: any): Promise<any> => {
  return new Promise((resolve) => {
    const items: any = [];
    let order = 0;

    /**
     *
     */
    const getAllSubItems = (item: any) => {
      // eslint-disable-next-line prefer-const
      let subIds: any = [];

      if (item.type !== undefined) {
        item.type.map((subItem: any) => {
          // Add id
          subIds.push(subItem.id);

          // If this subItem has subItems, loop through and also return those ID's
          const subSubItems = getAllSubItems(subItem);

          // Add each subItem to the subIds array
          if (subSubItems) {
            subSubItems.map((el: any) => {
              subIds.push(el);
            });
          }
        });
      }

      return subIds;
    };

    /**
     * Create the `label` and `value` object - takes a `depth` to create the prefix of `>`
     * @param item
     * @param depth
     * @returns
     */
    const createDropdownItem = (item: any, depth = 0): object => {
      return {
        label: item.label,
        value: item.id,
        def: item.def,
        depth,
        id: item.id,
        parent: item.parent,

        status: item.status,
        materialId: item.materialId,
        createdAt: item.createdAt,

        subItems: getAllSubItems(item),
        // eslint-disable-next-line no-plusplus
        order: order++,
      };
    };

    /**
     * Add item to the array and also check for sub items and loop through them and rerun this function again
     * @param item
     * @param depth
     */
    const itemLoop = (item: any, depth = 0) => {
      items.push(createDropdownItem(item, depth));

      if (item.type && item.type.length > 0) {
        item.type.map((subItem: any) => {
          itemLoop(subItem, depth + 1);
        });
      }
    };

    if (!isEmpty(dd)) {
      dd.map((item: any) => {
        itemLoop(item, 0);
      });
    }

    // return items;

    // setTimeout(() => {
    //   console.log("resolved..");
    //   return resolve(items);
    // }, 1000);

    resolve(items);
  });
};
