/* eslint-disable @typescript-eslint/no-explicit-any */
import { isNumber } from "lodash";

import { initialState } from "../types/initialState";

const SubmissionReducer = (state: any, action: any) => {
  switch (action.type) {
    case "insertMaterialMetadata": {
      const newState = { ...state, ...action.value };
      return newState;
    }

    case "insertAllFiles": {
      const newState = { ...state };
      if (action.value) {
        newState.objects = action.value;
      }
      return newState;
    }

    case "addObject": {
      const newState = { ...state };
      newState.objects.push(action.value);
      return newState;
    }

    case "removeObject": {
      const newState = { ...state };
      newState.objects = state.objects.filter(
        (item: any) => item.id !== action.value
      );
      return newState;
    }

    case "RESET_STATE": {
      return initialState;
    }

    case "UPDATE_STATE": {
      const newState = {
        ...state,
      };

      if (action.objectId) {
        newState.objectId = action.objectId;
      }

      if (action.objectMetadata) {
        newState.objectMetadata = action.objectMetadata;
      }

      return newState;
    }

    case "INSERT_METADATA": {
      let newState = { ...state };

      // Add material metadata to the state
      if (action.materialMetadata) {
        newState = { ...state, ...action.materialMetadata };
      }

      // Add the objectID (or material ID, or PK) to the state
      if (action.objectId) {
        newState.objectId = action.objectId;
      }

      // Add the object metadata to the state
      if (action.objectMetadata) {
        newState.objectMetadata = action.objectMetadata;
      }

      // Add the files metadata to the state
      if (action.filesMetadata) {
        newState.objects = action.filesMetadata;
      }

      // Add the doiReference to the state
      if (action.doi) {
        newState.doi = action.doi;
      }

      return newState;
    }

    // special dispatch function for materialType and primaryMaterialType
    case "materialTypeAndPrimaryTypeDispatch": {
      const newState = { ...state };
      const { value } = action;

      newState.instrument.materialType = value.materialType;
      newState.instrument.primaryMaterialType = value.primaryMaterialType;

      return newState;
    }

    // edit instrument
    case "instrumentChangeHandler": {
      const newState = { ...state };

      const { isMulti, i, value } = action;
      let { fieldName } = action;

      fieldName = isMulti ? fieldName.replace("[]", "") : fieldName;

      if (isMulti && isNumber(i)) {
        newState.instrument[fieldName][i] = value;
      } else {
        newState.instrument[fieldName] = value;
      }
      return newState;
    }

    // edit participant
    case "participantChangeHandler": {
      const newState = { ...state };

      const { isMulti, i, value } = action;
      let { fieldName } = action;

      fieldName = isMulti ? fieldName.replace("[]", "") : fieldName;

      if (isMulti && isNumber(i)) {
        newState.participant[fieldName][i] = value;
      } else {
        newState.participant[fieldName] = value;
      }

      return newState;
    }

    // edit publication
    case "publicationChangeHandler": {
      const newState = { ...state };

      const { isMulti, i, referenceNumber, value } = action;
      let { fieldName } = action;

      fieldName = isMulti ? fieldName.replace("[]", "") : fieldName;

      if (isMulti && isNumber(i)) {
        newState.publication[referenceNumber][fieldName][i] = value;
      } else {
        newState.publication[referenceNumber][fieldName] = value;
      }

      return newState;
    }

    // edit acknowledgement
    case "acknowledgementChangeHandler": {
      const newState = { ...state };

      const { isMulti, i, acknowledgementNumber, value } = action;
      let { fieldName } = action;

      fieldName = isMulti ? fieldName.replace("[]", "") : fieldName;

      // Extract the array
      const arr = newState.acknowledgement[acknowledgementNumber];

      if (fieldName === "type") {
        if (isMulti && isNumber(i)) {
          arr[fieldName][i] = value;
          arr.publication[i] = []; // <-- create empty array to store `publication type` input values in
        } else {
          arr[fieldName] = value;
          arr.publication = []; // <-- create empty array to store `publication type` input values in
        }
      } else if (isMulti && isNumber(i)) {
        arr.publication[fieldName][i] = value;
      } else {
        arr.publication[fieldName] = value;
      }

      // Insert the array
      newState.acknowledgement[acknowledgementNumber] = arr;

      return newState;
    }

    // edit settings
    case "settingsChangeHandler": {
      const newState = { ...state };

      const { isMulti, i, value } = action;
      let { fieldName } = action;

      fieldName = isMulti ? fieldName.replace("[]", "") : fieldName;

      if (isMulti && isNumber(i)) {
        newState.settings[fieldName][i] = value;
      } else {
        newState.settings[fieldName] = value;
      }

      return newState;
    }

    case "addAnotherPublicationHandler": {
      const newState = { ...state };
      newState.publication.push({
        publicationType: "",
      });
      return newState;
    }

    // add publication
    // ?

    // add acknowledgement
    case "addAnotherAcknowledgementHandler": {
      const newState = { ...state };
      newState.acknowledgement.push({});
      return newState;
    }

    // remove publication
    case "removePublicationHandler": {
      const newState = { ...state };
      if (action.value !== -1) {
        newState.publication.splice(action.value, 1);
      }
      return newState;
    }

    // remove acknowledgement
    // ??

    /**
     * When adding an acknowledgement the first thing you need to choose is the `acknowledgementType`.
     * This will create the object in which we store all the acknowledgement details.
     * acknowledgement: {
     *  type: "",
     *  publication: {...}
     * }
     */
    case "acknowledgementTypeChangeHandler": {
      const newState = { ...state };

      // on change we create an object in which we store all the details including the acknowledgementType
      newState.acknowledgement[action.acknowledgementNumber] = {};

      newState.acknowledgement[action.acknowledgementNumber][action.fieldName] =
        action.value;
      return newState;
    }

    // default state handler
    case "inputChangeHandler": {
      const newState = { ...state };
      if (isNumber(action.referenceNumber)) {
        newState.publication[action.referenceNumber][action.fieldName] =
          action.value;
      } else if (isNumber(action.acknowledgementNumber)) {
        newState.acknowledgement[action.acknowledgementNumber][
          action.fieldName
        ] = action.value;
      } else {
        newState[action.fieldName] = action.value;
      }
      return newState;
    }

    case "clearSubmissionState": {
      localStorage.removeItem("objectId");
      localStorage.removeItem("objectMetadata");
      return {};
    }

    default: {
      console.log(`Invalid action type: ${action.type}`);
      return state;
    }
  }
};

export default SubmissionReducer;
